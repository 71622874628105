import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';

import { CardVariants, EsgCardProps } from './types';
import { FBAccordion, FbAccordion } from '@fb/ui-kit';

const EsgCard: React.FC<EsgCardProps> = ({
  variant = CardVariants.DEFAULT,
  children,
  padding = 'p-5',
  height = 'h-auto',
  width = 'w-auto',
  color = 'bg-background',
  border = true,
  clickable = false,
  className = '',
  imageLeft = '',
  imageLeftBackground = 'bg-supportive-7',
  imageRight = '',
  imageRightBackground = 'bg-supportive-7',
  accordionTitle = '',
  isAccordionDefaultOpen = true,
  ...props
}) => {
  const cardClasses = classnames(
    {
      'rounded-2xl': true,
      'border border-monochrome-1': border,
      'shadow-cardShadow': variant === CardVariants.SHADOW,
      clickable: clickable,
    },
    padding,
    color,
    height,
    width,
    className,
  );

  const getCardContent = () => {
    if (imageLeft || imageRight) {
      return (
        <div className="flex w-full">
          <>
            {imageLeft && (
              <div className={`flex-none ${imageLeftBackground}`}>
                <Image
                  src={'/images/define-sustainability-vision-background.svg'}
                  alt={'title'}
                  width={160}
                  height={200}
                  className="h-full object-cover"
                />
              </div>
            )}
            <div className="flex-1">{children}</div>
            {imageRight && (
              <div className={`flex-none ${imageRightBackground}`}>
                <Image
                  src={'/images/define-sustainability-vision-background.svg'}
                  alt={'title'}
                  width={160}
                  height={200}
                  className="h-full object-cover"
                />
              </div>
            )}
          </>
        </div>
      );
    } else {
      return children;
    }
  };

  return (
    <div className={cardClasses} {...props}>
      {accordionTitle ? (
        <FbAccordion
          defaultValue={isAccordionDefaultOpen ? 'main' : ''}
          unstyled
          chevronPosition="right"
          classNames={{
            item: 'w-full',
            label: 'flex items-center ',
            control: 'flex gap-5 justify-between items-center w-full',
            itemTitle: 'flex justify-end items-center',
            chevron: 'order-2',
          }}
        >
          <FBAccordion.Item value="main">
            <FBAccordion.Control>
              <span className="font-bold text-body-text">{accordionTitle}</span>
            </FBAccordion.Control>
            <FBAccordion.Panel>{getCardContent()}</FBAccordion.Panel>
          </FBAccordion.Item>
        </FbAccordion>
      ) : (
        getCardContent()
      )}
    </div>
  );
};

export default EsgCard;
